<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 24">
    <path
      :style="iconStyle"
      d="M18-15.281V-15H12v-6h.281a1.085,1.085,0,0,1,.8.328l4.594,4.594A1.085,1.085,0,0,1,18-15.281ZM11.625-13.5H18V1.875a1.085,1.085,0,0,1-.328.8,1.085,1.085,0,0,1-.8.328H1.125a1.085,1.085,0,0,1-.8-.328A1.085,1.085,0,0,1,0,1.875v-21.75a1.085,1.085,0,0,1,.328-.8,1.085,1.085,0,0,1,.8-.328H10.5v6.375a1.085,1.085,0,0,0,.328.8A1.085,1.085,0,0,0,11.625-13.5ZM6.867-12.094a2.221,2.221,0,0,0-1.594-.656,2.143,2.143,0,0,0-1.57.656A2.17,2.17,0,0,0,3.047-10.5,2.17,2.17,0,0,0,3.7-8.906a2.143,2.143,0,0,0,1.57.656,2.221,2.221,0,0,0,1.594-.656,2.133,2.133,0,0,0,.68-1.594A2.133,2.133,0,0,0,6.867-12.094ZM15.047-1.5V-6.75L13.172-8.625a.517.517,0,0,0-.8,0L7.547-3.75,5.672-5.625a.469.469,0,0,0-.75,0L3.047-3.75V-1.5Z"
      transform="translate(0 21)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24',
    },
    width: {
      type: String,
      default: '18',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
